import React from "react"
import { graphql } from "gatsby"
import { Layout, Seo, Container, Button } from "../../components"

import { Android } from '/src/assets/vectors'

import Asset from '/src/assets/img/android_page_asset.png'

import * as styles from './style.module.scss'


const AndroidTemplate = ({ data, pageContext }) => {
	const lang = pageContext.lang === 'en_us' ? 'En' : 'Pt'
	const fields = data.android.androidPageFields
	
	return (
		<Layout>
			<Seo title={fields['headerTitle'+lang]} />

			<section className={styles.android}>
				<Container>
					<div className={styles.heroCard}>
						<div className={styles.heroCard__content}>
							<h1>{fields['headerTitle'+lang]}</h1>
							<p>{fields['headerSubtitle'+lang]}</p>
						</div>
						<div className={styles.heroCard__image}>
							<Android />
						</div>
					</div>

					<div className={`${styles.qrcode} flex align-center justify-center`}>
						<div
							className={styles.qrcode__content}
							dangerouslySetInnerHTML={{__html: fields['qrCodeContent'+lang]}}
						></div>
						<div className={styles.qrcode__image}>
							<img src={fields['qrCodeImage'+lang].localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="QR Code Download" />
						</div>
					</div>

					<div className={`${styles.steps} flex align-center justify-center`}>
						<img className={styles.image} src={fields['stepsImage'+lang].localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="Provisory asset" />

						<div className={styles.content}>
							{
								fields['steps'+lang].map((step, index) => {
									let stepText = lang === 'En' ? 'Step' : 'Passo';

									return (
										<div key={Math.random()} className={styles.step}>
											<h2>{++index}º {stepText}</h2>
											<p dangerouslySetInnerHTML={{__html: step.content}}></p>
										</div>
									)
								})
							}
						</div>

						<Button
							className={styles.button}
							href={fields.downloadLink.url}
							label="Download .apk"
							downloadable={true}
						/>
					</div>

					<div className={styles.card}>
						<p className={styles.card__title}>{fields['apkBoxTitle'+lang]}</p>
						<div
							className={styles.card__text}
							dangerouslySetInnerHTML={{__html: fields['apkBoxDescription'+lang]}}
						></div>
					</div>
				</Container>
			</section>
		</Layout>
	)
}

export const Query = graphql`
  query AndroidTemplate {
    android: wpPage(slug: { eq: "android" }) {
      id
      androidPageFields {
				downloadLink {
					url
				}
				headerTitleEn
				headerSubtitleEn
				qrCodeImageEn {
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: NONE
								layout: FULL_WIDTH
							)
						}
					}
				}
				qrCodeContentEn
				stepsImageEn {
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: NONE
								layout: FULL_WIDTH
							)
						}
					}
				}
				stepsEn {
					content
				}
				apkBoxTitleEn
				apkBoxDescriptionEn
				
				headerTitlePt
				headerSubtitlePt
				qrCodeImagePt {
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: NONE
								layout: FULL_WIDTH
							)
						}
					}
				}
				qrCodeContentPt
				stepsImagePt {
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: NONE
								layout: FULL_WIDTH
							)
						}
					}
				}
				stepsPt {
					content
				}
				apkBoxTitlePt
				apkBoxDescriptionPt
      }
    }
  }
`

export default AndroidTemplate
