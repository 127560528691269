// extracted by mini-css-extract-plugin
export var android = "style-module--android--3O_u3";
export var step = "style-module--step--RB_ui";
export var heroCard = "style-module--hero-card--2jafB";
export var heroCard__content = "style-module--hero-card__content--2GlEZ";
export var heroCard__image = "style-module--hero-card__image--3wY_Z";
export var qrcode = "style-module--qrcode--24eEp";
export var qrcode__content = "style-module--qrcode__content--3UoMd";
export var steps = "style-module--steps--2AW8b";
export var image = "style-module--image--2VmzZ";
export var button = "style-module--button--1hQjo";
export var content = "style-module--content--1PJeU";
export var card = "style-module--card--cgPlZ";
export var card__title = "style-module--card__title--3aCQ_";
export var card__text = "style-module--card__text--2m1w9";